.OnboardingImageCarousel button[aria-label="Previous"] {
  top: calc(90% - 20px) !important;
}

.OnboardingImageCarousel button[aria-label="Next"] {
  top: calc(90% - 20px) !important;
}

.Carousel-buttonWrapper-6.Carousel-fullHeightHoverWrapper-7.Carousel-prev-13 {
  left: 81%;
}

.OnboardingImageCarousel {
  height: 100%;
}

.CarouselItem {
  height: 100%;
}

.CarouselItem div {
  height: 100%;
}
